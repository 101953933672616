import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Features from './components/Features';
import Process from './components/Process';
import Mission from './components/Mission';
import AboutUs from './components/AboutUs';
import Testimonials from './components/Testimonials';
import SignUp from './components/SignUp';
import Footer from './components/Footer';

function App() {
  return (
    <div className="min-h-screen bg-[#132947]">
      <Header />
      <Hero />
      <Features />
      <Process />
      <Mission />
      <AboutUs />
      <Testimonials />
      <SignUp />
      <Footer />
    </div>
  );
}

export default App;