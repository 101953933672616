import React from 'react';

interface LogoProps {
  className?: string;
  size?: 'small' | 'medium' | 'large';
}

const Logo: React.FC<LogoProps> = ({ className = '', size = 'medium' }) => {
  const sizes = {
    small: 'h-16',
    medium: 'h-24',
    large: 'h-32 md:h-40'
  };

  // Using a direct Cloudinary URL
  const logoUrl = 'https://res.cloudinary.com/dsagh0nbi/image/upload/v1730834506/bond_coffee/nnurbq8jxfcfoueghpdh.svg';

  return (
    <div className={`${sizes[size]} ${className}`}>
      <img 
        src={logoUrl}
        alt="Bond Coffee Roasters" 
        className="h-full w-auto object-contain"
      />
    </div>
  );
};

export default Logo;