import React from 'react';
import { Coffee, Quote } from 'lucide-react';

export default function Testimonials() {
  const testimonials = [
    {
      text: "The finish is clean and a bit sweet. I enjoyed out of my Dartmouth College Thayer School of Engineering mug which brought back memories of leather bound books and smells of rich mahogany. 8/10",
      author: "Paul",
      role: "Coffee Connoisseur"
    },
    {
      text: "I hate to say it, but the coffee was good. A good logo and I might trade in my Pete's.",
      author: "Katie",
      role: "Reluctant Fan"
    },
    {
      text: "Anything my baby does, I'm a fan!",
      author: "Adam's Mom",
      role: "Proud Parent"
    }
  ];

  return (
    <section id="testimonials" className="py-20 relative overflow-hidden">
      <div className="absolute inset-0">
        {[...Array(6)].map((_, i) => (
          <Coffee
            key={i}
            className="absolute text-white/20 animate-float"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              width: `${Math.random() * 60 + 40}px`,
              height: `${Math.random() * 60 + 40}px`,
              animationDelay: `${Math.random() * 2}s`,
              animationDuration: `${Math.random() * 4 + 4}s`,
              transform: `rotate(${Math.random() * 360}deg)`,
              opacity: 0.8
            }}
          />
        ))}
        <div className="absolute inset-0 bg-[#132947]/80" />
      </div>

      <div className="container mx-auto px-4 relative">
        <h2 className="text-4xl font-bold text-center mb-16 text-[#fda36b]">
          What People Are Saying
        </h2>

        <div className="grid md:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {testimonials.map((testimonial, index) => (
            <div 
              key={index}
              className="bg-white/5 backdrop-blur-sm p-8 rounded-xl hover:bg-white/10 transition-all duration-300 flex flex-col"
            >
              <Quote className="w-10 h-10 text-[#fda36b] mb-4 self-start" />
              <p className="text-white/90 leading-relaxed mb-6 flex-grow italic">
                "{testimonial.text}"
              </p>
              <div>
                <p className="text-[#fda36b] font-semibold">- {testimonial.author}</p>
                <p className="text-white/60 text-sm">{testimonial.role}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}