import React from 'react';
import { Coffee, Home, Users, Globe } from 'lucide-react';

export default function AboutUs() {
  const founders = [
    {
      initials: "AQ",
      name: "Adam Questad",
      role: "Co-Founder",
      bio: "Pictured is a young Adam - dressed as a bunny - because Chris controls the website.",
      image: "https://res.cloudinary.com/dsagh0nbi/image/upload/v1730902799/2424BC3E-6A7E-449E-8B9A-517D72946160_zpqhwy.jpg"
    },
    {
      initials: "CB",
      name: "Chris Buchanan",
      role: "Co-Founder",
      bio: "Pictured is Ari - because nobody really needs to see a picture of Chris.",
      image: "https://res.cloudinary.com/dsagh0nbi/image/upload/v1730903081/3FC3372D-08BE-44EA-9F87-6D8162E8C7F3_gogxgu.jpg"
    }
  ];

  return (
    <section id="about" className="py-32 bg-[#0f1f35] relative overflow-hidden">
      <div className="absolute inset-0">
        {[...Array(6)].map((_, i) => (
          <Coffee
            key={i}
            className="absolute text-white/20 animate-float"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              width: `${Math.random() * 60 + 40}px`,
              height: `${Math.random() * 60 + 40}px`,
              animationDelay: `${Math.random() * 2}s`,
              animationDuration: `${Math.random() * 4 + 4}s`,
              transform: `rotate(${Math.random() * 360}deg)`,
              opacity: 0.8
            }}
          />
        ))}
      </div>

      <div className="container mx-auto px-4 relative">
        <div className="max-w-2xl mx-auto text-center mb-20">
          <h2 className="text-5xl font-bold mb-6 text-[#fda36b]">
            Meet Our Founders
          </h2>
          <p className="text-xl text-white/80">
            A story of friendship, community, and the perfect cup of coffee
          </p>
        </div>
        
        <div className="grid md:grid-cols-2 gap-16 max-w-5xl mx-auto mb-24">
          {founders.map((founder, index) => (
            <div key={index} className="group relative">
              <div className="absolute -inset-1 bg-gradient-to-r from-[#fda36b]/30 to-[#fda36b]/0 
                rounded-3xl blur opacity-30 group-hover:opacity-100 transition duration-500" />
              
              <div className="relative bg-gradient-to-br from-white/10 to-transparent backdrop-blur-sm 
                p-8 rounded-3xl border border-white/10 group-hover:border-[#fda36b]/30 
                transition-all duration-500">
                
                <div className="w-48 h-48 mx-auto mb-8 rounded-full overflow-hidden 
                  bg-gradient-to-br from-[#fda36b]/30 to-[#fda36b]/5 group-hover:from-[#fda36b]/40 
                  group-hover:to-[#fda36b]/10 transition-all duration-500">
                  {founder.image ? (
                    <img
                      src={founder.image}
                      alt={founder.name}
                      className="w-full h-full object-cover transform group-hover:scale-110 transition-transform duration-500"
                    />
                  ) : (
                    <div className="w-full h-full flex items-center justify-center transform 
                      group-hover:scale-110 transition-transform duration-500">
                      <span className="text-[#fda36b] text-5xl font-bold">{founder.initials}</span>
                    </div>
                  )}
                </div>
                
                <div className="text-center">
                  <h3 className="text-2xl font-bold mb-2 text-white group-hover:text-[#fda36b] 
                    transition-colors duration-300">{founder.name}</h3>
                  <p className="text-[#fda36b] mb-4">{founder.role}</p>
                  <p className="text-white/80 group-hover:text-white/90 transition-colors duration-300">
                    {founder.bio}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="max-w-4xl mx-auto">
          <div className="relative">
            <div className="absolute -inset-1 bg-gradient-to-r from-[#fda36b]/30 via-[#fda36b]/20 to-[#fda36b]/30 
              rounded-3xl blur-xl opacity-50" />
            
            <div className="relative bg-gradient-to-br from-white/10 to-transparent backdrop-blur-sm 
              p-12 rounded-3xl border border-white/10">
              
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
                <div className="text-center">
                  <Home className="w-12 h-12 text-[#fda36b] mx-auto mb-4" />
                  <p className="text-white/90">Next-door Neighbors</p>
                </div>
                <div className="text-center">
                  <Users className="w-12 h-12 text-[#fda36b] mx-auto mb-4" />
                  <p className="text-white/90">Close-knit Community</p>
                </div>
                <div className="text-center">
                  <Globe className="w-12 h-12 text-[#fda36b] mx-auto mb-4" />
                  <p className="text-white/90">Global Connection</p>
                </div>
              </div>

              <div className="space-y-6 text-lg leading-relaxed text-white/90">
                <p>
                  Our story begins with a simple twist of fate – Adam and Chris found themselves not just neighbors, 
                  but part of a vibrant, close-knit community where families naturally gravitate together, sharing 
                  life's moments both big and small.
                </p>
                
                <p>
                  Through countless evening conversations, weekend gatherings, and shared experiences with our 
                  neighbors, we discovered a mutual passion that perfectly aligned with our vision of bringing 
                  people together: coffee.
                </p>
                
                <p>
                  What started as casual discussions evolved into deep dives into the science of coffee, 
                  the intricacies of the roasting process, and the global network of relationships that bring 
                  these exceptional beans to our cups. We're fascinated by every aspect – from the chemistry 
                  behind the perfect roast to the stories of the farmers who nurture these crops.
                </p>
                
                <p>
                  While we're starting small, our vision is ambitious. We're building Bond Coffee Roasters 
                  not just as a business, but as a platform for creating the same kind of meaningful connections 
                  we've experienced in our own neighborhood – one cup of carefully crafted coffee at a time.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}