import React from 'react';
import { Leaf, Truck, Flame, Coffee } from 'lucide-react';

export default function Process() {
  const steps = [
    {
      icon: Leaf,
      title: "Bean Selection",
      description: "We're sourcing our beans from high-elevation farms (like, really high - 1,200-2,000 meters where the air is thin and the beans are stressed, but in a good way). Currently focusing on Central America and East Africa because, honestly, their volcanic soil makes everything taste better. It's like nature's secret sauce."
    },
    {
      icon: Truck,
      title: "Transportation",
      description: "Freshness is everything with coffee, so we're obsessive about how our beans travel from origin to roasting. We monitor conditions carefully to ensure the beans arrive in perfect condition, maintaining their complex flavors and subtle characteristics. Yes, we actually track all this data, because we're that kind of nerdy about it."
    },
    {
      icon: Flame,
      title: "Roasting",
      description: "While we're building up to having a full-scale roasting operation, we're already deep into the science of it. We're talking first crack temperatures, development time ratios, and heat curves that look like modern art. We're learning from the best and experimenting constantly to dial in our perfect roast..."
    },
    {
      icon: Coffee,
      title: "The Reality Check",
      description: "...but here's the real deal: we're a startup that's passionate about doing this right. While we're working on getting all the fancy equipment and perfecting our process, what we really want is to share great coffee with great people. Stop by, and we'll geek out about coffee science, show you what we're working on, or just share a cup and chat. We're building this from the ground up (coffee pun intended)."
    }
  ];

  return (
    <section id="process" className="py-32 relative overflow-hidden bg-[#0f1f35]">
      <div className="absolute inset-0">
        {[...Array(4)].map((_, i) => (
          <Coffee
            key={i}
            className="absolute text-white/20 animate-float"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              width: `${Math.random() * 60 + 40}px`,
              height: `${Math.random() * 60 + 40}px`,
              animationDelay: `${Math.random() * 2}s`,
              animationDuration: `${Math.random() * 4 + 4}s`,
              transform: `rotate(${Math.random() * 360}deg)`,
              opacity: 0.8
            }}
          />
        ))}
      </div>

      <div className="container mx-auto px-4 relative">
        <div className="max-w-2xl mx-auto text-center mb-20">
          <h2 className="text-5xl font-bold mb-6 text-[#fda36b]">
            The Process
          </h2>
          <p className="text-xl text-white/80">
            From bean to cup, we're learning and perfecting every step of the journey.
          </p>
        </div>

        <div className="grid md:grid-cols-2 gap-12 max-w-6xl mx-auto relative">
          {steps.map((step, index) => (
            <div 
              key={index}
              className="group relative"
            >
              <div className="absolute -inset-0.5 bg-gradient-to-r from-[#fda36b]/30 to-[#fda36b]/0 
                rounded-2xl blur opacity-30 group-hover:opacity-100 transition duration-500" />
              
              <div className="relative p-8 rounded-2xl bg-gradient-to-br from-white/10 to-transparent 
                backdrop-blur-sm border border-white/10 group-hover:border-[#fda36b]/30 
                transition-all duration-500 h-full">
                
                <step.icon className="w-16 h-16 text-[#fda36b] mb-6 transform group-hover:scale-110 
                  transition-transform duration-500" />
                
                <h3 className="text-2xl font-bold mb-4 text-white group-hover:text-[#fda36b] 
                  transition-colors duration-300">{step.title}</h3>
                
                <p className="text-white/80 leading-relaxed group-hover:text-white/90 
                  transition-colors duration-300">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}