import React, { useState } from 'react';
import { Menu, X } from 'lucide-react';
import Logo from './Logo';

const Header: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navItems = [
    { href: "#features", label: "Why Bond" },
    { href: "#process", label: "Process" },
    { href: "#mission", label: "Mission" },
    { href: "#about", label: "About" },
    { href: "#testimonials", label: "Reviews" },
    { href: "#signup", label: "Sign Up" }
  ];

  return (
    <header className="relative z-20 bg-[#132947]">
      <div className="absolute inset-0 overflow-hidden">
        <div 
          className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1447933601403-0c6688de566e?auto=format&fit=crop&q=100')] 
          opacity-60 bg-cover bg-center bg-fixed transform scale-105" 
          style={{ 
            filter: 'contrast(1.4) brightness(1.1) saturate(1.4)',
            transition: 'transform 0.5s ease-out'
          }}
        />
        <div className="absolute inset-0 bg-[#132947]/80" />
      </div>

      <div className="container mx-auto px-4 py-4 relative">
        <nav className="flex justify-between items-center">
          <Logo size="large" />
          
          <div className="hidden md:flex items-center">
            <div className="flex space-x-8">
              {navItems.map((item) => (
                <a 
                  key={item.href}
                  href={item.href} 
                  className="text-xl hover:text-[#fda36b] transition-colors text-white"
                >
                  {item.label}
                </a>
              ))}
            </div>
          </div>

          <button 
            className="md:hidden p-2 hover:bg-[#1a3557] rounded-lg transition-colors"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <X size={32} className="text-[#fda36b]" />
            ) : (
              <Menu size={32} className="text-[#fda36b]" />
            )}
          </button>
        </nav>

        {isMenuOpen && (
          <div className="md:hidden fixed inset-0 bg-[#132947] z-50 pt-20">
            <div className="flex flex-col items-center space-y-8 p-4">
              {navItems.map((item) => (
                <a 
                  key={item.href}
                  href={item.href} 
                  className="text-2xl font-semibold hover:text-[#fda36b] transition-colors text-white"
                  onClick={() => setIsMenuOpen(false)}
                >
                  {item.label}
                </a>
              ))}
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;