import React from 'react';
import { Coffee, Star, Heart, Truck } from 'lucide-react';

const features = [
  {
    icon: Coffee,
    title: 'Artisanal Roasting',
    description: 'Small-batch roasted coffee for optimal flavor and freshness'
  },
  {
    icon: Star,
    title: 'Premium Selection',
    description: 'Curated beans from the worlds most distinguished coffee regions'
  },
  {
    icon: Heart,
    title: 'Community Focus',
    description: 'Building connections through shared coffee experiences'
  },
  {
    icon: Truck,
    title: 'Monthly Delivery',
    description: 'Fresh coffee delivered to your door, right when you need it'
  }
];

export default function Features() {
  return (
    <section id="features" className="py-32 relative overflow-hidden">
      <div className="absolute inset-0">
        {[...Array(6)].map((_, i) => (
          <Coffee
            key={i}
            className="absolute text-white/20 animate-float"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              width: `${Math.random() * 60 + 40}px`,
              height: `${Math.random() * 60 + 40}px`,
              animationDelay: `${Math.random() * 2}s`,
              animationDuration: `${Math.random() * 4 + 4}s`,
              transform: `rotate(${Math.random() * 360}deg)`,
              opacity: 0.8
            }}
          />
        ))}
      </div>

      <div className="container mx-auto px-4 relative">
        <div className="max-w-2xl mx-auto text-center mb-20">
          <h2 className="text-5xl font-bold mb-6 text-[#fda36b]">
            Why Choose Bond Coffee
          </h2>
          <p className="text-xl text-white/80">
            Experience coffee excellence through our commitment to quality, community, and convenience.
          </p>
        </div>
        
        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto">
          {features.map((feature, index) => (
            <div 
              key={index}
              className="group relative p-8 rounded-2xl bg-gradient-to-br from-white/10 to-white/5 
                backdrop-blur-sm hover:from-white/15 hover:to-white/10 transition-all duration-500
                border border-white/10 hover:border-[#fda36b]/30"
            >
              <div className="absolute inset-0 bg-gradient-to-br from-[#fda36b]/0 to-[#fda36b]/0 
                group-hover:from-[#fda36b]/5 group-hover:to-transparent transition-all duration-500 rounded-2xl" />
              
              <feature.icon className="w-16 h-16 text-[#fda36b] mb-6 transform group-hover:scale-110 
                transition-transform duration-500" />
              
              <h3 className="text-2xl font-bold mb-4 text-white group-hover:text-[#fda36b] 
                transition-colors duration-300">{feature.title}</h3>
              
              <p className="text-white/80 group-hover:text-white/90 transition-colors duration-300">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}