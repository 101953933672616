import React from 'react';
import { Coffee } from 'lucide-react';

export default function Mission() {
  return (
    <section id="mission" className="py-32 relative overflow-hidden">
      <div className="absolute inset-0">
        {[...Array(6)].map((_, i) => (
          <Coffee
            key={i}
            className="absolute text-white/20 animate-float"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              width: `${Math.random() * 60 + 40}px`,
              height: `${Math.random() * 60 + 40}px`,
              animationDelay: `${Math.random() * 2}s`,
              animationDuration: `${Math.random() * 4 + 4}s`,
              transform: `rotate(${Math.random() * 360}deg)`,
              opacity: 0.8
            }}
          />
        ))}
        <div className="absolute inset-0 bg-[#132947]/80" />
      </div>
      
      <div className="container mx-auto px-4 relative">
        <div className="max-w-5xl mx-auto">
          <div className="text-center mb-16">
            <h2 className="text-5xl font-bold mb-6 text-[#fda36b]">
              Our Mission
            </h2>
          </div>

          <div className="relative">
            <div className="absolute -inset-1 bg-gradient-to-r from-[#fda36b]/30 via-[#fda36b]/20 to-[#fda36b]/30 
              rounded-3xl blur-xl opacity-50" />
            
            <div className="relative bg-gradient-to-br from-white/10 to-transparent backdrop-blur-sm 
              p-12 rounded-3xl border border-white/10">
              
              <div className="space-y-8">
                <p className="text-2xl leading-relaxed text-white/90">
                  At Bond Coffee Roasters, we believe in the power of coffee to bring people together. 
                  In a world that can feel increasingly divided, we're creating bridges and building bonds, 
                  one cup at a time.
                </p>
                
                <div className="w-24 h-1 bg-[#fda36b]/30 mx-auto" />
                
                <p className="text-2xl leading-relaxed text-white/90">
                  Our commitment to understanding coffee's journey from bean to cup 
                  helps us create meaningful connections between farmers, roasters, and coffee lovers.
                  Through every carefully roasted batch, we're fostering community and crafting moments 
                  of genuine connection.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}