import React from 'react';
import { ArrowRight } from 'lucide-react';

export default function Hero() {
  return (
    <section className="min-h-screen flex items-center relative overflow-hidden">
      <div 
        className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1447933601403-0c6688de566e?auto=format&fit=crop&q=100')] 
        opacity-60 bg-cover bg-center bg-fixed transform scale-105" 
        style={{ 
          filter: 'contrast(1.4) brightness(1.1) saturate(1.4)',
          transition: 'transform 0.5s ease-out'
        }}
      />

      <div className="container mx-auto px-4 -mt-48 md:-mt-32 relative z-10">
        <div className="max-w-4xl mx-auto text-center">
          <h1 className="text-5xl md:text-8xl font-bold mb-6 md:mb-12 leading-tight md:leading-tight tracking-tight text-white">
            <span className="text-white inline">Building </span>
            <span className="text-[#fda36b] inline">Bonds</span>
            <span className="block text-white">Through Coffee</span>
          </h1>
          
          <p className="text-xl md:text-3xl mb-8 md:mb-12 leading-relaxed max-w-3xl mx-auto text-white">
            Experience artisanal, small-batch coffee delivered to your door. 
            Join us in creating meaningful connections, one exceptional cup at a time.
          </p>
          
          <div className="flex flex-col sm:flex-row items-center justify-center gap-6">
            <a 
              href="#signup"
              className="group inline-flex items-center gap-2 bg-[#fda36b] text-[#132947] px-8 py-4 rounded-lg font-semibold text-lg hover:bg-[#fda36b]/90 transition-all duration-300 transform hover:scale-105"
            >
              Join the Waitlist
              <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </a>
            
            <a 
              href="#mission"
              className="inline-flex items-center gap-2 text-white hover:text-[#fda36b] transition-colors duration-300"
            >
              Discover Our Story
              <ArrowRight className="w-5 h-5" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}