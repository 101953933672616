import React, { useState } from 'react';
import { ArrowRight, Coffee, Check, PartyPopper } from 'lucide-react';

export default function SignUp() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');

  const successMessages = [
    "You're in! Your info is now safely stored between our coffee bean counting sessions (yes, we actually count them).",
    "Success! We've filed your info right next to our secret roasting recipe... which is currently a Post-it note that says 'make it taste good'.",
    "Welcome aboard! Your data is now hanging out with other cool people's data. They're already becoming friends.",
    "Got it! We'll add you to our list faster than Chris drinks his morning coffee (which is scary fast).",
    "You're officially part of the crew! Adam's mom will be so proud. She's proud of everything, but still.",
    "Success! We'll contact you right after we finish arguing about optimal brewing temperatures (current score: Chris 0, Science 1).",
    "High five! ✋ Your info is now more organized than our coffee bean storage system (which isn't saying much, but we're working on it).",
    "Awesome! We've added you to our list of 'People With Excellent Coffee Taste' (it's a very exclusive list... that now includes everyone).",
    "Woohoo! We'll keep your info safer than we keep our coffee stash (which is saying a lot, because Chris has three different locks on that).",
    "You're all set! We've filed your info in our high-tech database (it's definitely not just a really well-organized spreadsheet... okay, it is)."
  ];

  const getRandomMessage = () => {
    return successMessages[Math.floor(Math.random() * successMessages.length)];
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError('');

    const form = e.target as HTMLFormElement;
    const formData = new FormData(form);

    try {
      const response = await fetch('https://api.sheetmonkey.io/form/8Td6YXKEBDomuaiaCnn6Fp', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.get('name'),
          email: formData.get('email'),
          phone: formData.get('phone'),
          timestamp: new Date().toISOString()
        }),
      });

      if (response.ok) {
        setIsSuccess(true);
        form.reset();
        setTimeout(() => setIsSuccess(false), 8000);
      } else {
        throw new Error('Failed to submit');
      }
    } catch (err) {
      setError('Failed to submit. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section id="signup" className="py-20 relative overflow-hidden">
      <div className="absolute inset-0">
        {[...Array(6)].map((_, i) => (
          <Coffee
            key={i}
            className="absolute text-white/20 animate-float"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              width: `${Math.random() * 60 + 40}px`,
              height: `${Math.random() * 60 + 40}px`,
              animationDelay: `${Math.random() * 2}s`,
              animationDuration: `${Math.random() * 4 + 4}s`,
              transform: `rotate(${Math.random() * 360}deg)`,
              opacity: 0.8
            }}
          />
        ))}
        <div className="absolute inset-0 bg-[#132947]/80" />
      </div>

      <div className="container mx-auto px-4 relative">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-4xl font-bold mb-8 text-[#fda36b]">
            Join Our Coffee Community
          </h2>
          <p className="text-xl mb-12 text-white/90">
            Be the first to experience artisanal coffee delivered to your door. 
            Sign up for our waitlist and get early access to our subscription service.
          </p>

          {isSuccess && (
            <div className="fixed inset-0 flex items-center justify-center z-50 px-4">
              <div className="absolute inset-0 bg-black/50 backdrop-blur-sm" onClick={() => setIsSuccess(false)} />
              <div className="relative bg-[#132947] p-8 rounded-2xl max-w-md w-full border border-[#fda36b]/30 shadow-2xl">
                <div className="absolute -top-12 left-1/2 -translate-x-1/2">
                  <div className="bg-[#fda36b] rounded-full p-4">
                    <PartyPopper className="w-8 h-8 text-[#132947]" />
                  </div>
                </div>
                <div className="mt-4 text-center">
                  <h3 className="text-2xl font-bold text-[#fda36b] mb-4">You're Awesome!</h3>
                  <p className="text-white text-lg leading-relaxed">{getRandomMessage()}</p>
                  <button
                    onClick={() => setIsSuccess(false)}
                    className="mt-6 px-6 py-2 bg-[#fda36b] text-[#132947] rounded-lg font-semibold hover:bg-[#fda36b]/90 transition-colors"
                  >
                    Can't Wait!
                  </button>
                </div>
              </div>
            </div>
          )}

          <form onSubmit={handleSubmit} className="max-w-md mx-auto space-y-6">
            <div>
              <input
                type="text"
                name="name"
                placeholder="Your Name"
                className="w-full px-6 py-4 bg-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#fda36b] placeholder-white/50 text-white"
                required
              />
            </div>
            
            <div>
              <input
                type="email"
                name="email"
                placeholder="Email Address"
                className="w-full px-6 py-4 bg-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#fda36b] placeholder-white/50 text-white"
                required
              />
            </div>
            
            <div>
              <input
                type="tel"
                name="phone"
                placeholder="Phone Number"
                className="w-full px-6 py-4 bg-white/10 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#fda36b] placeholder-white/50 text-white"
                required
              />
            </div>

            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full bg-[#fda36b] text-[#132947] px-8 py-4 rounded-lg font-semibold text-lg 
                hover:bg-[#fda36b]/90 transition-all duration-300 group flex items-center justify-center gap-2
                disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isSubmitting ? (
                'Submitting...'
              ) : (
                <>
                  Join the Waitlist
                  <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </>
              )}
            </button>

            {error && (
              <p className="text-red-400 text-sm text-center">{error}</p>
            )}
          </form>
        </div>
      </div>
    </section>
  );
}