import React from 'react';
import Logo from './Logo';

const Footer: React.FC = () => {
  return (
    <footer className="bg-[#132947] text-white py-12">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
          <Logo size="medium" />
          
          <div className="flex space-x-8">
            <a href="#about" className="hover:text-[#fda36b] transition-colors">About</a>
            <a href="#mission" className="hover:text-[#fda36b] transition-colors">Mission</a>
            <a href="#signup" className="hover:text-[#fda36b] transition-colors">Sign Up</a>
          </div>
        </div>
        
        <div className="mt-8 text-center text-sm text-gray-400">
          <p>© 2024 Bond Coffee Roasters. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;